import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { getAuth, User as FirebaseUser, signInWithEmailLink, signInAnonymously } from '@firebase/auth'

import { State } from '@/store/index'
import { User } from '@/types/User'
import config from '@/configs/config'

interface AuthProvider {
  getIdToken(): Promise<string>
}

export interface AuthState {
  provider?: AuthProvider,
  user?: User,
  tag?: number
}

export const authStore = <Module<AuthState, State>> {
  state: <AuthState> {
    login: undefined,
    user: undefined
  },
  mutations: <MutationTree<AuthState>> {
    onAuthenticate (state: AuthState, data: {provider: AuthProvider, user: User}) {
      state.provider = data.provider
      state.user = data.user
    },
    onLogout (state: AuthState) {
      state.provider = undefined
      state.user = undefined
    },
    onTag (state: AuthState, tag: string) {
      if (!isNaN(Number(tag))) {
        state.tag = Number(tag)
      }
    }
  },
  getters: <GetterTree<AuthState, State>> {
    user: (state: AuthState): User | undefined => {
      return state.user
    },
    token: (state: AuthState): Promise<string> | undefined => {
      return state.provider?.getIdToken()
    },
    tag: (state: AuthState): number | undefined => {
      return state.tag
    }
  },
  actions: <ActionTree<AuthState, State>>{
    tagUser: async (context: ActionContext<AuthState, State>) => {
      const tag = context.getters.tag as number
      const user = context.getters.user as User

      if (tag && user && !user.tagId) {
        await User.setTagById(tag)
      }
    },
    logout: async (context: ActionContext<AuthState, State>) => {
      const provider = context.state.provider
      if (provider as FirebaseUser) {
        await getAuth().signOut()
      }
      context.commit('onLogout')
    },
    login: async (context: ActionContext<AuthState, State>, provider: AuthProvider) => {
      try {
        const user = await User.fetchByToken(await provider.getIdToken())
        context.commit('onAuthenticate', { provider: provider, user: user })
        context.dispatch('tagUser')
      } catch (error) {
        context.commit('onError', error)
        await context.dispatch('logout')
      }
    },
    loginWithFirebasePasswordless: async (context: ActionContext<AuthState, State>, data) => {
      try {
        const credentials = await signInWithEmailLink(getAuth(), data.email, data.url)
        window.localStorage.removeItem(config.STORAGE.FIREBASE_EMAIL)
        await context.dispatch('login', credentials.user)
      } catch (error) {
        context.commit('onError', error)
        await context.dispatch('logout')
      }
    },
    loginWithFirebaseAnonymously: async (context: ActionContext<AuthState, State>) => {
      try {
        const credentials = await signInAnonymously(getAuth())
        await context.dispatch('login', credentials.user)
      } catch (error) {
        context.commit('onError', error)
        await context.dispatch('logout')
      }
    },
    loginWithCoursera: async (context: ActionContext<AuthState, State>, token) => {
      try {
        await context.dispatch('login', new CourseraProvider(token))
      } catch (error) {
        context.commit('onError', error)
        await context.dispatch('logout')
      }
    },
    loadAuthState: async (context: ActionContext<AuthState, State>) => {
      if (!context.state.provider) {
        const provider = await getFirebaseUser()
        if (provider) {
          await context.dispatch('login', provider)
        }
      }
    }
  }
}

// see https://github.com/firebase/firebase-js-sdk/issues/462
const getFirebaseUser = async (): Promise<FirebaseUser | null> => {
  return new Promise<FirebaseUser | null>((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user: FirebaseUser | null) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

class CourseraProvider implements AuthProvider {
  token: string

  constructor (token: string) {
    this.token = token
  }

  getIdToken (): Promise<string> {
    return new Promise((resolve) => { resolve(this.token) })
  }
}
