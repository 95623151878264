import { Store, ModuleTree } from 'vuex'

import { AuthState, authStore } from '@/store/auth'
import { ChatState, chatStore } from '@/store/chat'
import { ErrorState, errorStore } from '@/store/error'
import { VideoState, videoStore } from '@/store/video'
import { SocketState, socketStore } from '@/store/socket'

export interface State {
  auth: AuthState
  chat: ChatState
  error: ErrorState
  socket: SocketState
  video: VideoState
}

export const store = new Store<State>({
  modules: <ModuleTree<State>> {
    auth: authStore,
    chat: chatStore,
    error: errorStore,
    socket: socketStore,
    video: videoStore
  }
})
