import { defineComponent } from 'vue'
import { getAuth, isSignInWithEmailLink } from '@firebase/auth'
import {
  createRouter, createWebHistory, NavigationGuardNext,
  RouteLocationNormalized, RouteRecordRaw
} from 'vue-router'

import { User, UserRole } from '@/types/User'
import { store } from '@/store/index'
import config from '@/configs/config'
import { logError } from '@/main'

export const routes: Record<string, RouteRecordRaw> = {
  chat: {
    path: '/',
    name: 'Chat',
    alias: '/chat',
    meta: {
      role: UserRole.USER
    },
    component: () => import('@/components/pages/chat/ChatPage.vue')
  },
  tags: {
    path: '/tags',
    name: 'Tags',
    meta: {
      role: UserRole.ADMIN
    },
    component: () => import('@/components/pages/dashboard/tags/TagPage.vue')
  },
  users: {
    path: '/users',
    name: 'Users',
    meta: {
      role: UserRole.ADMIN
    },
    component: () => import('@/components/pages/dashboard/users/UserPage.vue')
  },
  export: {
    path: '/export',
    name: 'Export',
    meta: {
      role: UserRole.ADMIN
    },
    component: () => import('@/components/pages/dashboard/export/ExportPage.vue')
  },
  login: {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/pages/LoginPage.vue')
  },
  auth: {
    path: '/auth',
    name: 'Auth',
    component: () => defineComponent({})
  },
  default: {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
}

export const router = createRouter({
  history: createWebHistory('/'),
  routes: Object.values(routes)
})

router.onError((error: any, to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  logError(error)
})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  await store.dispatch('loadAuthState')
  let user: User = store.getters.user

  if (to.path === routes.auth.path) {
    if (to.query.game) {
      store.commit('onSetGreeting', to.query.game)
    }

    if (to.query.tag) {
      store.commit('onTag', to.query.tag)
      store.dispatch('tagUser')
    }

    if (!user && to.query.type) {
      switch (to.query.type) {
        case 'email': {
          const url = window.location.href
          if (isSignInWithEmailLink(getAuth(), url)) {
            let email = window.localStorage.getItem(config.STORAGE.FIREBASE_EMAIL)
            if (!email) {
              email = window.prompt('Please confirm your email:') ?? ''
            }
            await store.dispatch('loginWithFirebasePasswordless', { url: url, email: email })
            user = store.getters.user
          }
        } break
        case 'anon':
          await store.dispatch('loginWithFirebaseAnonymously')
          user = store.getters.user
          break
        case 'lti':
          await store.dispatch('loginWithCoursera', to.query.key)
          user = store.getters.user
          break
      }
    }

    next({ path: routes.default.path })
    return
  }

  if (user) {
    if (to.path === routes.login.path) {
      next({ path: routes.default.path })
      return
    }
  }

  if (to.meta.role !== undefined) {
    if (!user) {
      next({ path: routes.login.path })
      return
    }

    switch (to.meta.role) {
      case UserRole.USER:
        if (
          user.role !== UserRole.USER &&
          user.role !== UserRole.ADMIN
        ) {
          next({ path: routes.default.path })
          return
        }
        break
      case UserRole.ADMIN:
        if (
          user.role !== UserRole.ADMIN
        ) {
          next({ path: routes.default.path })
          return
        }
        break
    }
  }
  next()
})
