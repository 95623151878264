import { GetterTree, Module, MutationTree } from 'vuex'

import { State } from '@/store/index'
import { logError } from '@/main'

export interface ErrorState {
  error?: Error,
}

export const errorStore = <Module<ErrorState, State>> {
  state: <ErrorState> {
    error: undefined
  },
  mutations: <MutationTree<ErrorState>> {
    onError (state: ErrorState, error: Error) {
      state.error = error
      if (error) {
        logError(error, false)
      }
    }
  },
  getters: <GetterTree<ErrorState, State>> {
    error: (state: ErrorState): Error | undefined => {
      return state.error
    }
  }
}
