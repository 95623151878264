import FIREBASE_CONFIG from '@/configs/firebase.json'

export default {
  DEVELOPMENT: process.env.NODE_ENV === 'development',
  API_BACKEND: process.env.VUE_APP_API_BACKEND ?? 'https://negotiation-api.andrewkostka.com',
  RASA_BACKEND: process.env.VUE_APP_RASA_BACKEND ?? 'https://negotiation-rasa.andrewkostka.com',
  AUTH_ENDPOINT: `${window.location.origin}/auth`,
  STORAGE: {
    FIREBASE_EMAIL: 'emailForSignIn'
  },
  FIREBASE: {
    CONFIG: FIREBASE_CONFIG
  },
  CHAT: {
    DOM_PURIFY: { ALLOWED_TAGS: ['b', 'q', 'br', 'strong', 'i', 'mark', 's', 'sub', 'sup'] },
    DELAY: true
  }
}
