import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import config from '@/configs/config'
import { State, store } from '@/store/index'
import {
  Message, MessageModel, MessageReply, MessageSender, MessageStatsModel,
  MessageTextModel, MessageVideoModel
} from '@/types/Message'

export interface ChatState {
  messages: MessageModel[]
  replies: MessageReply[]
  messageQueue: {callback: () => void, delay: number}[]
  waitingForBotResponse: boolean,
  inputHidden: boolean,
  timeLastMessage: number
  dequeuerActive: boolean
}

export const chatStore = <Module<ChatState, State>> {
  state: <ChatState> {
    messages: [],
    replies: [],
    messageQueue: [],
    waitingForBotResponse: true,
    inputHidden: false,
    timeLastMessage: 0,
    dequeuerActive: false
  },
  mutations: <MutationTree<ChatState>> {
    onMessageReceive (state: ChatState, data: {
      message: string,
      replies?: { 'content_type': string, title: string, payload: string }[],
      data?: { [name: string]: unknown }
    }) {
      state.waitingForBotResponse = true
      console.log(`Received message: ${data.message}`)

      const message = Message.parse(data.message, data.data)
      const replies = (data.replies ?? [])
        .filter((reply) => reply.content_type === 'text')
        .map((reply) => new MessageReply(reply.title, reply.payload))

      const delay = 1000 - (Date.now() - state.timeLastMessage)
      state.timeLastMessage = Date.now()

      state.messageQueue.push({
        callback: () => {
          state.messages.push(message)

          if (message instanceof MessageTextModel) {
            state.inputHidden = (message as MessageTextModel).final
          }

          if (replies.length === 1 && replies[0].payload === '/continue') {
            if (message instanceof MessageVideoModel) {
              state.replies = replies
            }

            if (
              !(message instanceof MessageVideoModel) &&
              !(message instanceof MessageStatsModel)
            ) {
              store.commit('onMessageSend', { payload: '/continue', message: false })
            }
          } else {
            state.replies = replies
          }
        },
        delay: config.CHAT.DELAY && delay > 0 ? delay : 0
      })
      store.dispatch('dequeuer')
    },
    onMessageSend (state: ChatState, data: { payload: string, message?: string | false }) {
      if (store.getters.socket) {
        store.getters.socket.emit('user_uttered', { message: data.payload })
        console.log(`Sent message: ${data.payload}`)
        if (data.message !== false) {
          state.messages.push(new MessageTextModel(
            MessageSender.USER, data.message ?? data.payload
          ))
        }
        state.replies = []
        state.waitingForBotResponse = true
        state.timeLastMessage = Date.now()
      }
    },
    onToggleInput (state: ChatState, data: boolean) {
      state.inputHidden = !data
    }
  },
  getters: <GetterTree<ChatState, State>> {
    dequeuerActive: (state: ChatState): boolean => {
      return state.dequeuerActive
    },
    waitingForBotResponse: (state: ChatState): boolean => {
      return state.waitingForBotResponse
    },
    inputHidden: (state: ChatState): boolean => {
      return state.inputHidden
    },
    messageQueue: (state: ChatState): {callback: () => void, delay: number}[] => {
      return state.messageQueue
    },
    messages: (state: ChatState): MessageModel[] => {
      return state.messages
    },
    replies: (state: ChatState): MessageReply[] => {
      return state.replies
    }
  },
  actions: <ActionTree<ChatState, State>>{
    dequeuer: async (context: ActionContext<ChatState, State>) => {
      if (!context.state.dequeuerActive) {
        context.state.dequeuerActive = true
        const next = context.state.messageQueue.shift()
        if (next) {
          setTimeout(() => {
            next.callback()
            context.state.dequeuerActive = false
            if (context.state.messageQueue.length === 0) {
              context.state.waitingForBotResponse = false
            } else {
              context.dispatch('dequeuer')
            }
          }, next.delay)
        }
      }
    }
  }
}
