import { createApp } from 'vue'

import App from './App.vue'
import { router } from './router'
import { store } from './store'

import config from '@/configs/config'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import { initializeApp } from '@firebase/app'

import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import zoomPlugin from 'chartjs-plugin-zoom'
import chartDataLabelsPlugin from 'chartjs-plugin-datalabels'

import SetupCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

Chart.register(...registerables)
Chart.register(annotationPlugin)
Chart.register(zoomPlugin)
Chart.register(chartDataLabelsPlugin)

initializeApp(config.FIREBASE.CONFIG)

export const logError = (error: any, fatal = true) => {
  if (fatal) {
    console.error(error)
  } else {
    console.warn(error)
  }
}

const app = createApp(App)

window.addEventListener('unhandledrejection', (event: any) => {
  logError(event.reason)
})

window.onerror = (msg: any, url: any, line: any, col: any, error: any) => {
  logError(error)
}

app.config.errorHandler = (error: any, instance: any, info: any) => {
  logError(error)
}

app.use(store).use(router).use(SetupCalendar).mount('#app')
