import { GetterTree, Module, MutationTree } from 'vuex'
import Player from '@vimeo/player'

import { State } from '@/store/index'

export interface VideoState {
  activeVideo?: Player,
  latestVideo?: Player,
  videoEnded: boolean,
  videoEnding: boolean
}

export const videoStore = <Module<VideoState, State>> {
  state: <VideoState> {
    activeVideo: undefined,
    latestVideo: undefined,
    videoEnded: false,
    videoEnding: false
  },
  mutations: <MutationTree<VideoState>> {
    onVideoPlay (state: VideoState, player: Player) {
      state.activeVideo = player
    },
    onVideoCreated (state: VideoState, player: Player) {
      state.latestVideo = player
      state.videoEnded = false
      state.videoEnding = false
    },
    onVideoEnded (state: VideoState, player: Player) {
      if (state.latestVideo === player) {
        state.latestVideo = undefined
        state.videoEnded = true
      }
    },
    onVideoEnding (state: VideoState, player: Player) {
      if (state.latestVideo === player) {
        state.videoEnding = true
      }
    }
  },
  getters: <GetterTree<VideoState, State>> {
    activeVideo: (state: VideoState) => {
      return state.activeVideo
    },
    videoEnded: (state: VideoState) => {
      return state.videoEnded
    },
    videoEnding: (state: VideoState) => {
      return state.videoEnding
    }
  }
}
