export enum MessageSender {
  BOT,
  USER
}

export class Message {
  static REGEX_VIDEO = /\[video:vimeo:(.*):(.*)\]/
  static REGEX_STATISTICS = /\[statistics:(.*)\]/
  static REGEX_ERROR = /\[error\]/
  static REGEX_ECHO = /\[echo\]/
  static REGEX_FINAL = /\[final\]/

  static parse (message: string, data: { [name: string]: unknown } = {}): MessageModel {
    let matches: RegExpMatchArray | null

    matches = message.match(this.REGEX_VIDEO)
    if (matches && matches.length === 3) {
      return new MessageVideoModel(
        MessageSender.BOT, matches[1], matches[2]
      )
    }

    matches = message.match(this.REGEX_STATISTICS)
    if (matches && matches.length === 2) {
      const text = message.replace(this.REGEX_STATISTICS, '')
      return new MessageStatsModel(
        MessageSender.BOT, text, data
      )
    }

    matches = message.match(this.REGEX_ERROR)
    if (matches && matches.length === 1) {
      const text = message.replace(this.REGEX_ERROR, '')
      return new MessageErrorModel(
        MessageSender.BOT, text
      )
    }

    matches = message.match(this.REGEX_ECHO)
    if (matches && matches.length === 1) {
      const text = message.replace(this.REGEX_ECHO, '')
      return new MessageEchoModel(
        MessageSender.BOT, text
      )
    }

    matches = message.match(this.REGEX_FINAL)
    if (matches && matches.length === 1) {
      const text = message.replace(this.REGEX_FINAL, '')
      return new MessageTextModel(
        MessageSender.BOT, text, true
      )
    }

    return new MessageTextModel(
      MessageSender.BOT, message
    )
  }
}

export class MessageModel {
  sender: MessageSender

  constructor (sender: MessageSender) {
    this.sender = sender
  }

  isUserMessage (): boolean {
    return this.sender === MessageSender.USER
  }
}

export class MessageTextModel extends MessageModel {
  text: string
  final: boolean

  constructor (sender: MessageSender, text: string, final = false) {
    super(sender)
    this.text = text
    this.final = final
  }

  get lines (): string[] {
    return this.text.split('\n')
  }
}

export class MessageEchoModel extends MessageTextModel {

}

export class MessageErrorModel extends MessageTextModel {

}

export class MessageVideoModel extends MessageModel {
  id: string
  hash?: string

  constructor (sender: MessageSender, id: string, hash?: string) {
    super(sender)
    this.id = id
    this.hash = hash
  }

  get url (): string {
    let url = `https://player.vimeo.com/video/${this.id}`
    if (this.hash) {
      url += `?h=${this.hash}`
    }
    return url
  }
}

export class MessageStatsModel extends MessageTextModel {
  data: {[name: string]: unknown}

  constructor (sender: MessageSender, text: string, data: {[name: string]: unknown}) {
    super(sender, text)
    this.data = data
  }
}

export class MessageReply {
  private static HIDDEN_PAYLOADS = [
    '/continue'
  ]

  text: string
  payload: string

  constructor (text: string, payload: string) {
    this.text = text
    this.payload = payload
  }

  getButtonText (): string {
    return this.text
  }

  getMessageText (): string | false {
    return MessageReply.HIDDEN_PAYLOADS.includes(this.payload) ? false : this.text
  }
}
