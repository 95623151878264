import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { io, Socket } from 'socket.io-client'

import { State, store } from '@/store/index'
import config from '@/configs/config'

enum Greeting {
  HELLO = 'hello',
  ULTIMATUM = 'ultimatum',
  ZINCIT = 'zincit',
  ANCHORING = 'anchoring',
  OUTPSIDER = 'outpsider'
}

export interface SocketState {
  socketSentGreeting: boolean
  socketRetryDelay: number
  socket?: Socket,
  greeting?: Greeting
}

export const socketStore = <Module<SocketState, State>> {
  state: <SocketState> {
    socketSentGreeting: false,
    socketRetryDelay: 0,
    socket: undefined,
    greeting: Greeting.HELLO
  },
  mutations: <MutationTree<SocketState>> {
    onSocket (state: SocketState, socket?: Socket) {
      console.log('Socket.io initialized')
      state.socket = socket
    },
    onSocketConnect (state: SocketState) {
      console.log('Socket.io connected')
      state.socketRetryDelay = 2500
      if (!state.socketSentGreeting) {
        store.commit('onMessageSend', { payload: '/restart', message: false })
        store.commit('onMessageSend', { payload: state.greeting, message: false })
        state.socketSentGreeting = true
      }
    },
    onSocketBotMessage (state: SocketState, message) {
      store.commit('onMessageReceive', {
        message: message.text,
        replies: message.quick_replies,
        data: message.data
      })
    },
    onSocketConnectError (state: SocketState, error) {
      console.log(`Socket.io connect_error: ${error}`)
      store.commit('onError', error)
      if (error.message === 'invalid credentials') {
        state.socketRetryDelay += (state.socketRetryDelay < 15000) ? 2500 : 5000
        console.log(`Socket.io trying again in ${state.socketRetryDelay}ms`)
        setTimeout(() => {
          console.log('Socket.io trying again with refreshed credentials')
          store.getters.token.then((token?: string) => {
            if (state.socket) {
              state.socket.auth = { token: token }
              state.socket.connect()
            }
          })
        }, state.socketRetryDelay)
      }
    },
    onSocketError (state: SocketState, error) {
      console.log(`Socket.io error: ${error}`)
      store.commit('onError', error)
    },
    onSetGreeting (state: SocketState, greeting: string) {
      if ((Object.values(Greeting)).includes(greeting as Greeting)) {
        state.greeting = greeting as Greeting
      }
    }
  },
  getters: <GetterTree<SocketState, State>> {
    socketRetryDelay: (state: SocketState): number => {
      return state.socketRetryDelay
    },
    socket: (state: SocketState): Socket | undefined => {
      return state.socket
    }
  },
  actions: <ActionTree<SocketState, State>>{
    openSocket: async (context: ActionContext<SocketState, State>) => {
      if (!context.getters.socket) {
        const socket = io(config.RASA_BACKEND, {
          path: '/socket.io/',
          transports: ['websocket'],
          auth: async (callback) => {
            const data = { token: await context.getters.token }
            callback(data)
          }
        })
        socket.on('connect', () => context.commit('onSocketConnect'))
        socket.on('bot_uttered', (message) => context.commit('onSocketBotMessage', message))
        socket.on('error', (error) => context.commit('onSocketError', error))
        socket.on('connect_error', (error) => context.commit('onSocketConnectError', error))
        context.commit('onSocket', socket)
      }
    },
    closeSocket: async (context: ActionContext<SocketState, State>) => {
      if (context.getters.socket) {
        context.getters.socket.disconnect()
        context.commit('onSocket', undefined)
      }
    }
  }
}
