<template>
  <div class="app-container container-md">
    <div class="row nav">
      <nav-bar/>
    </div>
    <div
      class="alert alert-danger alert-dismissible fade show"
      v-if="error"
      role="alert"
    >
      {{ error }}
      <button
        class="btn-close"
        type="button"
        data-bs-dismiss="alert"
        aria-label="Close"
        @click="this.$store.commit('onError', undefined)"
      >
      </button>
    </div>
    <div class="row content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import NavBar from '@/components/NavBar.vue'

export default defineComponent({
  components: {
    NavBar
  },
  computed: {
    error () {
      return this.$store.getters.error
    }
  }
})
</script>

<style>
@import './assets/styles/colors.css';
body, html {
  height: 100%;
  overflow: hidden;
}
#app {
  background-image: var(--app-gradient-c);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100%;
  overflow: auto;
}
.app-container {
  --app-navbar-height: 60px;
  background-color: white;
  margin-top: calc(100vh * 0.05);
  border-radius: 10px;
  overflow: auto;
  height: 85%;
  display: flex;
  flex-flow: column;
}
/* see https://getbootstrap.com/docs/5.1/layout/breakpoints/ */
@media (max-width: 767.98px) {
  .app-container {
    height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
}
.nav {
  height: var(--app-navbar-height);
  flex: 0 1 auto;
}
.alert {
  flex: 0 1 auto;
  margin-top: 12px;
}
.content {
  flex: 1 1 auto;
  overflow-y: hidden;
}
</style>
