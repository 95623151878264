import { Tag } from './Tag'
import { request } from '@/helpers/Backend'

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user'
}

export enum UserProvider {
  FIREBASE = 'firebase',
  MAINTENANCE = 'maintenance',
  COURSERA = 'coursera'
}

export interface UserUpdateAttributes {
  role?: UserRole,
  tag?: {
    name: string
  }
}

export interface UserAttributes {
  id: number
  role: UserRole
  email?: string
  tagId?: number
  tag?: Tag
  provider: UserProvider
  created: Date
  modified: Date
}

export class User implements UserAttributes {
  id: number
  role: UserRole
  email?: string
  provider: UserProvider
  tagId?: number
  tag?: Tag
  created: Date
  modified: Date

  get admin (): boolean {
    return this.role === UserRole.ADMIN
  }

  private constructor (attr: UserAttributes) {
    this.id = attr.id
    this.role = attr.role
    this.provider = attr.provider
    this.created = new Date(attr.created)
    this.modified = new Date(attr.modified)
  }

  static deserialize (json: UserAttributes): User {
    if (!Object.values(UserRole).includes(json.role as UserRole)) {
      throw Error('Received an invalid user role from the backend')
    }
    if (!Object.values(UserProvider).includes(json.provider as UserProvider)) {
      throw Error('Received an invalid user provider from the backend')
    }
    const user = new User(json)
    user.tagId = json.tagId
    user.email = json.email
    user.tag = json.tag ? Tag.deserialize(json.tag) : undefined
    return user
  }

  static async fetchByToken (token: string): Promise<User> {
    return this.deserialize(await request({
      method: 'GET', resource: 'user/me', header: { authorization: `Bearer ${token}` }
    }))
  }

  static async setTagById (id: number): Promise<User> {
    return this.deserialize(await request({
      method: 'POST', resource: `user/tag/${id}`
    }))
  }

  static async update (id: number, attr: UserUpdateAttributes): Promise<User> {
    return this.deserialize(await request({
      method: 'POST', resource: `user/${id}`, data: attr
    }))
  }

  static async get (query: string, offset: number): Promise<{users: User[], total: number, offset: number}> {
    const params = query.split(',').reduce((map, item) => {
      const separator = item.indexOf(':')
      if (separator > 0) {
        map[item.substring(0, separator)] = item.substring(separator + 1)
      }
      return map
    }, {} as { [name: string]: string })

    const response = await request({
      method: 'GET',
      resource: 'user',
      params: {
        ...(params.email && { email: params.email }),
        ...(params.id && { id: params.id }),
        ...(params.tag && { tag: params.tag }),
        ...(!Object.keys(params).length && { email: query }),
        offset: offset
      }
    })

    return {
      users: response.items.map((user: UserAttributes) => this.deserialize(user)),
      total: response.total,
      offset: response.offset
    }
  }

  static async getById (id: number): Promise<User> {
    return this.deserialize(await request({
      method: 'GET', resource: `user/${id}`
    }))
  }

  static async resetUltimatumById (id: number) {
    await request({ method: 'DELETE', resource: `user/${id}/ultimatum` })
  }

  static async resetZincitById (id: number) {
    await request({ method: 'DELETE', resource: `user/${id}/zincit` })
  }

  static async resetAnchoringById (id: number) {
    await request({ method: 'DELETE', resource: `user/${id}/anchoring` })
  }

  static async resetOutpsiderById (id: number) {
    await request({ method: 'DELETE', resource: `user/${id}/outpsider` })
  }
}
