
import { defineComponent } from 'vue'
import Collapse from 'bootstrap/js/dist/collapse'

import { UserProvider, UserRole } from '@/types/User'
import { routes } from '@/router'

export default defineComponent({
  name: 'NavBar',
  data () {
    return {
      closeMenuCallback: (event: Event) => {
        const navBar = this.$refs.navbar as HTMLElement
        const target = event.target as HTMLElement

        if (!navBar.contains(target)) {
          window.removeEventListener('click', (this as any).closeMenuCallback)
          new Collapse(this.$refs.userMenu as HTMLElement).hide()

          if (this.isMobile()) {
            new Collapse(this.$refs.menu as HTMLElement).hide()
          } else {
            (this.$refs.menu as HTMLElement).classList.remove('show')
          }
        }
      }
    }
  },
  computed: {
    isAuthenticated (): boolean {
      return this.$store.getters.user
    },
    isAdmin (): boolean {
      return this.$store.getters.user.role === UserRole.ADMIN
    },
    displayName (): string {
      if (this.$store.getters.user.email) {
        return `${this.$store.getters.user.email} (${this.$store.getters.user.id}) `
      } else if (this.$store.getters.user.provider === UserProvider.COURSERA) {
        return `Coursera (${this.$store.getters.user.id}) `
      } else {
        return `Anonymous (${this.$store.getters.user.id}) `
      }
    }
  },
  beforeUnmount () {
    window.removeEventListener('click', this.closeMenuCallback)
  },
  methods: {
    async logout () {
      this.toggleMenu()
      await this.$store.dispatch('logout')
      this.$router.push({ name: routes.login.name })
    },
    isMobile (): boolean {
      const mobileMenuToggle = this.$refs.mobileMenuToggle as HTMLElement
      return window.getComputedStyle(mobileMenuToggle).display !== 'none'
    },
    toggleMenu () {
      const menu = this.$refs.menu as HTMLElement
      const userMenu = this.$refs.userMenu as HTMLElement
      const showMenu = !userMenu.classList.contains('show') ||
        (this.isMobile() && !menu.classList.contains('show'))

      if (showMenu) {
        userMenu.classList.add('show')
        if (this.isMobile()) {
          new Collapse(menu).show()
        }
        window.addEventListener('click', this.closeMenuCallback)
      } else {
        userMenu.classList.remove('show')
        if (this.isMobile()) {
          new Collapse(menu).hide()
        }
        window.removeEventListener('click', this.closeMenuCallback)
      }
    }
  }
})
