import config from '@/configs/config'
import { request } from '@/helpers/Backend'

export interface TagCreationAttributes {
  name: string
  description?: string | null
}

export interface TagUpdateAttributes {
  description?: string | null
}

export interface TagAttributes {
  id: number
  name: string
  description?: string
  created: Date
  modified: Date
}

export class Tag implements TagAttributes {
  id: number
  name: string
  description?: string
  created: Date
  modified: Date

  get url (): string {
    const url = new URL(config.AUTH_ENDPOINT)
    url.searchParams.set('tag', String(this.id))
    return url.toString()
  }

  private constructor (attr: TagAttributes) {
    this.id = attr.id
    this.name = attr.name
    this.created = new Date(attr.created)
    this.modified = new Date(attr.modified)
  }

  static deserialize (json: TagAttributes): Tag {
    const tag = new Tag(json)
    tag.description = json.description
    return tag
  }

  static async create (attr: TagCreationAttributes): Promise<Tag> {
    return this.deserialize(await request({
      method: 'POST', resource: 'tag', data: attr
    }))
  }

  static async update (id: number, attr: TagUpdateAttributes): Promise<Tag> {
    return this.deserialize(await request({
      method: 'POST', resource: `tag/${id}`, data: attr
    }))
  }

  static async get (query: string, offset: number): Promise<{tags: Tag[], total: number, offset: number}> {
    const params = query.split(',').reduce((map, item) => {
      const separator = item.indexOf(':')
      if (separator > 0) {
        map[item.substring(0, separator)] = item.substring(separator + 1)
      }
      return map
    }, {} as { [name: string]: string })

    const response = await request({
      method: 'GET',
      resource: 'tag',
      params: {
        ...(params.id && { id: params.id }),
        ...(params.name && { name: params.name }),
        ...(!Object.keys(params).length && { name: query }),
        offset: offset
      }
    })

    return {
      tags: response.items.map((tag: TagAttributes) => this.deserialize(tag)),
      total: response.total,
      offset: response.offset
    }
  }

  static async getById (id: number): Promise<Tag> {
    return this.deserialize(await request({
      method: 'GET', resource: `tag/${id}`
    }))
  }
}
