/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { AxiosError, Method } from 'axios'

import config from '@/configs/config'
import { store } from '@/store'

export interface Request {
  method: Method,
  resource: string,
  params?: any,
  header?: any,
  data?: any
}

export const request = async (request: Request): Promise<any> => {
  return await handler(request)
}

export const download = async (request: Request) => {
  return await handler(request, { prefix: 'negotiation' })
}

const handler = async (request: Request, download?: { prefix: string }) => {
  request.header = request.header ?? {}
  if (!request.header.authorization) {
    request.header.authorization = `Bearer ${await store.getters.token}`
  }

  try {
    const data = (await axios({
      method: request.method,
      url: `${config.API_BACKEND}/api/${request.resource}`,
      headers: request.header,
      params: request.params,
      data: request.data,
      ...(download) && { responseType: 'blob' }
    })).data

    if (download) {
      return save(data, download.prefix)
    }
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      let data = (error as AxiosError).response?.data
      if (data instanceof Blob) {
        data = JSON.parse(await data.text())
      }
      throw new Error(JSON.stringify(data))
    }
    throw error
  }
}

const save = (data: Blob, prefix: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.setAttribute('download', `${prefix}-${new Date().getTime()}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
